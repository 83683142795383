<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
    value: {},
    reviewCount: {},
    label: String,
    bigStars: Boolean,
});

const roundedRating = computed(() => {
    return Math.round(props.value * 2) / 2;
});
</script>
<template>
    <div class="flex items-center gap-1 text-pink">
        <div v-for="i in 5" :key="i">
            <span v-if="roundedRating == i - 0.5">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :width="bigStars ? 24 : 17"
                    :height="bigStars ? 24 : 17"
                    viewBox="0 0 12 12"
                >
                    <path
                        id="star_half_FILL0_wght400_GRAD0_opsz48"
                        d="M10,7.135V12l2.055,1.56L11.23,10.96l1.89-1.23H10.855ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                        transform="translate(-4 -4)"
                        fill="currentColor"
                    />
                </svg>
            </span>
            <span v-else-if="roundedRating >= i">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :width="bigStars ? 24 : 17"
                    :height="bigStars ? 24 : 17"
                    viewBox="0 0 12 12"
                >
                    <path
                        id="star_FILL0_wght400_GRAD0_opsz48"
                        d="M6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                        transform="translate(-4 -4)"
                        fill="currentColor"
                    />
                </svg>
            </span>
            <span v-else-if="roundedRating < i">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    :width="bigStars ? 24 : 17"
                    :height="bigStars ? 24 : 17"
                    viewBox="0 0 12 12"
                >
                    <path
                        id="grade_FILL0_wght400_GRAD0_opsz48"
                        d="M10,10.345ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Zm1.65-2.445L10,12l2.055,1.56L11.23,10.96l1.89-1.23H10.855L10,7.135l-.855,2.6H6.88l1.89,1.23Z"
                        transform="translate(-4 -4)"
                        fill="currentColor"
                    />
                </svg>
            </span>
        </div>

        <span v-if="reviewCount" class="flex items-end text-base text-blue"> ({{ reviewCount }}) </span>
    </div>
</template>
